/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
// import {useAuth} from '../../../../app/modules/auth'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {IUsuairo} from '../../../../types/Usuarios'
import { authContext } from '../../../../configs/adal.config'

const HeaderUserMenu: FC<Partial<IUsuairo>> = (infoUsuario) => {
  //constants
  const BLANK_PROFILE_IMAGE = toAbsoluteUrl('/assets/avatars/blank.png')

  // const {currentUser, logout} = useAuth()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={infoUsuario?.imagenURL || BLANK_PROFILE_IMAGE} />
          </div>

          <div className='w-70' style={{width: '70%'}}>
            <div className='fw-bolder d-flex align-items-center'>
              <p className='m-0 text-truncate'>
                {infoUsuario?.nombreApellido || 'Nombre Apellido'}
              </p>
            </div>
            <div className='fw-bold text-muted fs-7'>
              <p className='m-0 text-truncate'>{infoUsuario?.email || 'user@email.com'}</p>
            </div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a
          onClick={() => {
            authContext.logOut()
          }}
          className='menu-link px-5'
        >
          <KTSVG path={toAbsoluteUrl('/assets/icons/user-menu/logout.svg')} className='menu-icon' />
          Cerrar sesión
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
