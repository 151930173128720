import {runWithAdal} from 'react-adal'
import {authContext} from './configs/adal.config'

const DO_NOT_LOGIN = false

runWithAdal(
  authContext,
  () => {
    require('./appIndex')
  },
  DO_NOT_LOGIN
)
