import clsx from 'clsx'
import { useUsuario } from '../../../../Hooks/useUsuario'
import {toAbsoluteUrl} from '../../../helpers'
// import {HeaderNotificationsMenu, ThemeModeSwitcher} from '../../../partials'
import {HeaderUserMenu} from '../../../partials'
import { QSearch } from '../../../partials/layout/search/QSearch'

const itemClass = 'ms-1 ms-lg-3'
// const btnClass =
//   'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
// const btnIconClass = 'svg-icon-1'

const Navbar = () => {
    //constants
    const BLANK_PROFILE_IMAGE = toAbsoluteUrl('/assets/avatars/blank.png')
  //hooks
  const {infoUsuario} = useUsuario()
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <QSearch />
      </div>

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTSVG path='/media/icons/duotune/general/gen032.svg' className={btnIconClass} />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={infoUsuario?.imagenURL || BLANK_PROFILE_IMAGE} alt='' />
        </div>
        <HeaderUserMenu {...infoUsuario}/>
      </div>
    </div>
  )
}

export {Navbar}
