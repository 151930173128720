import {createAsyncThunk} from '@reduxjs/toolkit'
import TablerosService, {
  ListarTablerosFavoritosRequest,
  ListarTablerosPorAreaRequest,
  ListarTablerosPorBusquedaRequest,
  ListarTablerosRequest,
  ToggleFavRequest,
} from '../../services/TablerosService'
import {ITablero} from '../../types/Tableros'

type ErrorType = {
  rejectValue: {
    errorMessage: string
  }
}

async function fetchTableros(requestData: ListarTablerosRequest = {}) {
  try {
    const response = await TablerosService.getTableros(requestData)
    if (response.status !== 200) {
      return null
    }
    return response.data.tableros
  } catch (error) {
    console.error(error)
    return null
  }
}

async function fetchTablerosFavoritos(requestData: ListarTablerosFavoritosRequest) {
  try {
    const response = await TablerosService.getTablerosFavoritos(requestData)
    if (response.status !== 200) {
      return null
    }
    return response.data.tableros
  } catch (error) {
    console.error(error)
    return null
  }
}

async function toggleFavoritos(requestData: ToggleFavRequest) {
  try {
    const response = await TablerosService.toggleTableroFavorito(requestData)
    if (response.status !== 204) {
      return null
    }
    return true
  } catch (error) {
    console.error(error)
    return null
  }
}

export const obtenerTableros = createAsyncThunk<Array<ITablero>, void, ErrorType>(
  'tableros/obtenerTableros',
  async (_, thunkAPI) => {
    const data = await fetchTableros()

    return (
      data ??
      thunkAPI.rejectWithValue({
        errorMessage: 'No se pudieron obtener los tableros.',
      })
    )
  }
)

export const obtenerTablerosPorArea = createAsyncThunk<
  Array<ITablero>,
  ListarTablerosPorAreaRequest,
  ErrorType
>('tableros/obtenerTablerosPorArea', async (requestData, thunkAPI) => {
  const data = await fetchTableros(requestData)

  return (
    data ??
    thunkAPI.rejectWithValue({
      errorMessage: 'No se pudieron obtener los tableros.',
    })
  )
})

export const obtenerTablerosPorBusqueda = createAsyncThunk<
  Array<ITablero>,
  ListarTablerosPorBusquedaRequest,
  ErrorType
>('tableros/obtenerTablerosPorBusqueda', async (requestData, thunkAPI) => {
  const data = await fetchTableros(requestData)

  return (
    data ??
    thunkAPI.rejectWithValue({
      errorMessage: 'No se pudieron obtener los tableros.',
    })
  )
})

export const obtenerTablerosFavoritos = createAsyncThunk<
  Array<ITablero>,
  ListarTablerosFavoritosRequest,
  ErrorType
>('tableros/obtenerTablerosFavoritos', async (requestData, thunkAPI) => {
  const data = await fetchTablerosFavoritos(requestData)

  return (
    data ??
    thunkAPI.rejectWithValue({
      errorMessage: 'No se pudieron obtener los tableros.',
    })
  )
})

export const marcarTableroFavorito = createAsyncThunk<
  {favResult: boolean; tableroId: ITablero['tableroId']},
  ToggleFavRequest,
  ErrorType
>('tableros/marcarTableroFavorito', async (requestData, thunkAPI) => {
  const data = await toggleFavoritos(requestData)

  return data
    ? {favResult: data, tableroId: requestData.tableroId}
    : thunkAPI.rejectWithValue({
        errorMessage: 'No se pudo marcar o desmarcar el tablero como favorito.',
      })
})
