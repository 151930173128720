import {FC} from 'react'
import {useIntl} from 'react-intl'
import {useTableros} from '../../../Hooks/useTableros'
import { useUsuario } from '../../../Hooks/useUsuario'
import { IUsuairo } from '../../../types/Usuarios'
import {PageTitle} from '../../../_metronic/layout/core'
import {TarjetaTablero} from '../../../_metronic/partials/widgets/cards/TarjetaTablero'

const DashboardPage: FC = () => {
  const {tableros, reqStatus, filtrandoPorFavoritos} = useTableros()
  const {infoUsuario} = useUsuario()

  return (
    <>
      <div className='row g-5 g-xl-10 m-auto mb-5 mb-xl-10'>
        {reqStatus === 'loading' ? (
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-4 m-auto mb-md-5 mb-xl-10'>
            <h4>Cargando resultados...</h4>
          </div>
        ) : tableros === null || tableros.length === 0 ? (
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-4 m-auto mb-md-5 mb-xl-10'>
            <h4>No se encontraron resultados</h4>
          </div>
        ) : (
          tableros.map((tablero) => (
            <div
              className={`col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-2 mt-0 ${filtrandoPorFavoritos && !tablero.favorito && 'd-none' }`}
              key={tablero.tableroId}
            >
              <TarjetaTablero className='mb-5 mb-xl-8' {...{...tablero, usuarioId: infoUsuario?.usuarioId as IUsuairo["usuarioId"]} }/>{' '}
            </div>
          ))
        )}
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
