import {useEffect, useState} from 'react'
import UsuariosService from '../services/UsuariosService'
import {IUsuairo} from '../types/Usuarios'

type InfoRequest = {
  status: 'success' | 'failed'
  errorMessage?: string
}

// const testUser: IUsuairo = {
//   usuarioId: 1234,
//   nombreApellido: 'Carlos Mandrake',
//   email: 'mandrakecarlos@gmail.com',
//   imagenURL: 'https://placekitten.com/400/400',
// }

export const useUsuario = () => {
  //states
  const [infoUsuario, setInfoUsuario] = useState<IUsuairo>()
  const [infoRequest, setinfoRequest] = useState<InfoRequest>()

  //effects
  useEffect(() => {
    fetchInfoUsuario()
    //setinfoRequest({status: 'success'})
  }, [])

  //methods
  async function fetchInfoUsuario() {
    try {
      const response = await UsuariosService.getDatosUsuario()
      if (response.status === 200) {
        setinfoRequest({status: 'success'})
        setInfoUsuario(response.data.usuario)
      } else {
        throw new Error('Error al obtener la información de usuario')
      }
    } catch (error) {
      console.log(error)
      setinfoRequest({
        status: 'failed',
        errorMessage: 'Error al obtener la información de usuario',
      })
    }
  }

  return {
    infoUsuario,
    infoRequest,
    fetchInfoUsuario,
  }
}
