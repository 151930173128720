import {IUsuairo} from '../types/Usuarios'
import api from './apiConfig'

type DatosDeUsuario = {
  usuario: IUsuairo
}

const getDatosUsuario = () => {
  return api.get<DatosDeUsuario>('/datosUsuario')
}

const UsuariosService = {
  getDatosUsuario,
}

export default UsuariosService
