import React, {useEffect, useRef, useState} from 'react'
import {useTableros} from '../../../../Hooks/useTableros'
import {KTSVG} from '../../../helpers'

export const QSearch = () => {
  const [query, setQuery] = useState('')
  const [showSearchbar, setShowSearchbar] = useState(false)
  const {filtrarTablerosPorBusqueda} = useTableros()
  const searchInputElement = useRef<HTMLInputElement>(null)

  const clearInputValue = () => {
    setQuery('');
    if (searchInputElement.current?.value) {
      searchInputElement.current.value = ''
    }
  }

  const dissmis = () => {
    setShowSearchbar(false)
  }

  const dispatchQuery = () => {
    if (query.trim().length > 2) {
      filtrarTablerosPorBusqueda(query)
    }
    return dissmis()
  }

  const handleKeyEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      dispatchQuery()
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value
    setQuery(targetValue)
  }

  useEffect(() => {
    if (showSearchbar) {
      searchInputElement.current?.focus()
    }
  }, [showSearchbar])

  return (
    <div className='m-auto position-relative d-inline-block q-search-component'>
      <span onClick={() => {
        setShowSearchbar(true)
        clearInputValue()
      }}>
        <KTSVG
          path='/assets/icons/gen021.svg'
          className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 translate-middle-y ms-0'
        />
      </span>
      <form
        className={`w-100 mb-3 position-absolute p-1 card q-dropdown-search ${
          showSearchbar || 'd-none'
        }`}
        autoComplete='off'
        onSubmit={(e) => e.preventDefault()}
      >
        <span onClick={dispatchQuery}>
          <KTSVG
            path='/assets/icons/gen021.svg'
            className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
          />
        </span>
        <input
          type='text'
          className='form-control form-control-flush ps-10'
          name='q-search'
          id='q-search'
          placeholder='Buscar...'
          defaultValue={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyEvent}
          onBlur={() => setTimeout(dissmis, 300)}
          ref={searchInputElement}
        />

        <span className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'>
          <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
        </span>
      </form>
    </div>
  )
}
