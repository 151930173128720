import {IArea} from '../types/Areas'
import api from './apiConfig'

export type ListadoAreas = {
  areas: Array<IArea>
}

const getAreas = () => {
  return api.get<ListadoAreas>('/listadoAreas')
}

const AreasService = {
  getAreas,
}

export default AreasService
