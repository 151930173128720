/* eslint-disable jsx-a11y/anchor-is-valid */

import {useEffect, useState} from 'react'
import {useTableros} from '../../../../../Hooks/useTableros'
import { useUsuario } from '../../../../../Hooks/useUsuario'
// import { useAppDispatch } from "../../../../../store/hooks"
//import {obtenerTableros, obtenerTablerosFavoritos} from '../../../../../store/Tableros/actions'

const ACTIVIDAD_GENERAL = 'Actividad General'
const FAVORITOS = 'Favoritos'

const ToolbarTabs = () => {
  //const dispatch = useAppDispatch();
  const {infoUsuario} = useUsuario()
  const [selectedTab, setSelectedTab] = useState(ACTIVIDAD_GENERAL)
  const {listarTableros, listarTablerosFavoritos, filtrandoPorFavoritos} = useTableros()

  const handleTabChange = (tab: string) => {
    if (tab === selectedTab) return

    setSelectedTab(tab)

    if (tab === ACTIVIDAD_GENERAL) return listarTableros()

    if (tab === FAVORITOS) return listarTablerosFavoritos(infoUsuario?.usuarioId)
  }

  useEffect(() => {
    if (filtrandoPorFavoritos) {
      setSelectedTab(FAVORITOS)
    } else {
      setSelectedTab(ACTIVIDAD_GENERAL)
    }
  }, [filtrandoPorFavoritos])

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        <li className='nav-item'>
          <span
            className={`nav-a text-active-primary me-6 ${
              selectedTab === ACTIVIDAD_GENERAL && 'active'
            }`}
            onClick={() => handleTabChange(ACTIVIDAD_GENERAL)}
          >
            {ACTIVIDAD_GENERAL}
          </span>
        </li>
        <li className='nav-item'>
          <span
            className={`nav-a text-active-primary me-6 ${selectedTab === FAVORITOS && 'active'}`}
            onClick={() => handleTabChange(FAVORITOS)}
          >
            {FAVORITOS}
          </span>
        </li>
      </ul>
    </div>
  )
}

export {ToolbarTabs}
