import {IArea} from '../types/Areas'
import {ITablero} from '../types/Tableros'
import {IUsuairo} from '../types/Usuarios'
import api from './apiConfig'

export type ListarTablerosRequest = {
  areaId?: IArea['areaId']
  busqueda?: string
}

export type ListarTablerosPorBusquedaRequest = {
  busqueda: ListarTablerosRequest['busqueda']
}

export type ListarTablerosPorAreaRequest = {
  areaId: ListarTablerosRequest['areaId']
}

export type ListarTablerosFavoritosRequest = {
  usuarioId: IUsuairo['usuarioId']
}

export type ListadoTableros = {
  tableros: Array<ITablero>
}

export type ToggleFavRequest = {
  tableroId: ITablero['tableroId']
}

const getTableros = (reqParams: ListarTablerosRequest) => {
  return api.get<ListadoTableros>('/listadoTableros', {
    params: {
      areaId: reqParams.areaId,
      busqueda: reqParams.busqueda,
    },
  })
}

const getTablerosFavoritos = (reqParams: ListarTablerosFavoritosRequest) => {
  return api.get<ListadoTableros>('/listadoTablerosFavoritos', {
    params: {
      usuarioId: reqParams.usuarioId,
    },
  })
}

const toggleTableroFavorito = (reqParams: ToggleFavRequest) => {
  return api.post('/tableroFavorito', reqParams)
}

const TablerosService = {
  getTableros,
  getTablerosFavoritos,
  toggleTableroFavorito,
}

export default TablerosService
