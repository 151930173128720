/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useTableros} from '../../../../Hooks/useTableros'
import {ITablero} from '../../../../types/Tableros'
import {IUsuairo} from '../../../../types/Usuarios'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

interface Props extends Omit<ITablero, 'areaId'>, Pick<IUsuairo, 'usuarioId'> {
  className: string
}

const TarjetaTablero: React.FC<Props> = ({
  className,
  tableroId,
  nombreTablero,
  descripcionTablero,
  iconoURL,
  hipervinculoTablero,
  imagenTableroURL,
  tableroPalabrasClave,
  favorito,
}) => {
  const {toogleTableroFavorito, favLoading} = useTableros()
  return (
    <div className={`card shadow ${className}`}>
      {/* begin::Body */}
      <div className='card-body pb-0'>
        {/* begin::Header */}
        <div className='d-flex align-items-center mb-5'>
          {/* begin::User */}
          <div className='d-flex align-items-center flex-grow-1'>
            {/* begin::Avatar */}
            <div className='symbol symbol-25px me-5'>
              <img src={iconoURL} alt='' />
            </div>
            {/* end::Avatar */}

            {/* begin::Info */}
            <div className='d-flex flex-column'>
              <a
                href={hipervinculoTablero || '#'}
                target='_blank'
                rel='noreferrer'
                className='text-gray-800 text-hover-primary fs-6 fw-bold'
              >
                {nombreTablero}
              </a>
            </div>
            {/* end::Info */}
          </div>
          {/* end::User */}

          {/* begin::Menu */}
          <div className='my-0'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={() => toogleTableroFavorito({tableroId})}
            >
              {favLoading ? (
                  <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
                // <span className='position-absolute top-50 end-0 translate-middle-y lh-0 me-1'>
                // </span>
              ) : (
                <KTSVG
                  path={`/assets/icons/star-${favorito ? 'fill' : 'outline'}.svg`}
                  className={`svg-icon-2`}
                />
              )}
            </button>
          </div>
          {/* end::Menu */}
        </div>
        {/* end::Header */}
        {/* begin::Post */}
        <div className='mb-5'>
          {/* begin::Image */}
          <a target='_blank' rel='noreferrer' href={hipervinculoTablero || '#'} className='overlay'>
            <div className='overlay-wrapper'>
              <div
                className='bgi-no-repeat bgi-size-cover rounded min-h-250px mb-5 border border-secondary'
                style={{
                  backgroundImage: `url('${
                    imagenTableroURL ?? toAbsoluteUrl('/assets/img/default-placeholder.png')
                  }')`,
                  backgroundPosition: `${imagenTableroURL ? 'left' :  'center'}`,
                }}
              ></div>
            </div>
            <div className='overlay-layer bg-dark bg-opacity-10 align-items-center justify-content-center'>
              <div className='bg-light mx-4 px-4 py-2 text-dark rounded'>
                <p className='font-weight-bold'>{descripcionTablero}</p>
              </div>
            </div>
          </a>
          {/* end::Image */}
        </div>
        {/* end::Post */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TarjetaTablero}
