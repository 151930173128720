/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout} from '../modules/auth'
//import {AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import api from '../../services/apiConfig'
import {IUsuairo} from '../../types/Usuarios'
//import { authContext } from '../../configs/adal.config'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  // const {currentUser} = useAuth()
  const [usuario, setUsuario] = useState<IUsuairo>()
  const [userWasFetched, setUserWasFetched] = useState(false)
  const BASE_URL = process.env.REACT_APP_API_URL

  useEffect(() => {
    const fetchUsuario = async () => {
      try {
        const response = await api.get<{usuario: IUsuairo}>(`${BASE_URL}verificarUsuario`)

        if (response.data.usuario) {
          setUsuario(response.data.usuario)
        }
      } catch (error) {
        console.error(error)
      } finally{
        setUserWasFetched(true)
      }
    }
    fetchUsuario()
  }, [BASE_URL])

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {userWasFetched &&
            (usuario ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/dashboard' />} />
              </>
            ) : (
              <Route index element={<Navigate to='/error/500' />} />
            ))}
          {/* {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )} */}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
