import {FC} from 'react'

const Error500: FC = () => {
  const cleanLocalStorage = () => {
    localStorage.clear();
    window.location.href = window.location.origin
  }
  return (
    <>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2qx text-gray-900 mb-4'>Algo salió mal.</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className='fw-semibold fs-6 text-gray-500 mb-7'>
        Por favor comuniquese con el administrador del sistema.
      </div>
      {/* end::Text */}

      {/* begin::Link */}
      <div className='mb-0'>
        <button className='btn btn-sm btn-primary' onClick={cleanLocalStorage}>
          Volver a iniciar sesión.
        </button>
      </div>
      {/* end::Link */}
    </>
  )
}

export {Error500}
