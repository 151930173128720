/* eslint-disable react/jsx-no-target-blank */
import { useAreas } from '../../../../../Hooks/useAreas'
import {QSidebarMenuItem} from './QSideBarMenuIitem'

const SidebarMenuMain = () => {
  const {areas} = useAreas();
  return (
    <>
      <QSidebarMenuItem
        icon='/assets/icons/sidebar/Home.svg'
        title='Home'
        fontIcon='bi-app-indicator'
      />
      {areas?.map(area => (
              <QSidebarMenuItem
              key={area.areaId}
              icon={area.iconoURL}
              title={area.nombreArea}
              areaId={area.areaId}
              fontIcon='bi-app-indicator'
            />
      ))}
    </>
  )
}

export {SidebarMenuMain}
