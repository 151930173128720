import {createSlice} from '@reduxjs/toolkit'
import {ITablero} from '../../types/Tableros'
import {
  marcarTableroFavorito,
  obtenerTableros,
  obtenerTablerosFavoritos,
  obtenerTablerosPorArea,
  obtenerTablerosPorBusqueda,
} from './actions'

type TablerosStateType = {
  status: 'idle' | 'loading' | 'failed'
  list: Array<ITablero> | null
  filstrandoPorFavoritos: boolean
  errorMessage?: string
}

const initialState: TablerosStateType = {
  status: 'idle',
  list: null,
  filstrandoPorFavoritos: false,
}

export const TablerosSlice = createSlice({
  name: 'tableros',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(obtenerTableros.pending, (state) => {
        state.status = 'loading'
        state.filstrandoPorFavoritos = false
      })
      .addCase(obtenerTableros.rejected, (state, {payload}) => {
        state.status = 'failed'
        state.errorMessage = payload?.errorMessage
        state.filstrandoPorFavoritos = false
      })
      .addCase(obtenerTableros.fulfilled, (state, {payload}) => {
        state.list = payload
        state.status = 'idle'
        state.filstrandoPorFavoritos = false
      })
      .addCase(obtenerTablerosPorArea.pending, (state) => {
        state.status = 'loading'
        state.filstrandoPorFavoritos = false
      })
      .addCase(obtenerTablerosPorArea.rejected, (state, {payload}) => {
        state.status = 'failed'
        state.errorMessage = payload?.errorMessage
        state.filstrandoPorFavoritos = false
      })
      .addCase(obtenerTablerosPorArea.fulfilled, (state, {payload}) => {
        state.list = payload
        state.status = 'idle'
        state.filstrandoPorFavoritos = false
      })
      .addCase(obtenerTablerosPorBusqueda.pending, (state) => {
        state.status = 'loading'
        state.filstrandoPorFavoritos = false
      })
      .addCase(obtenerTablerosPorBusqueda.rejected, (state, {payload}) => {
        state.status = 'failed'
        state.errorMessage = payload?.errorMessage
        state.filstrandoPorFavoritos = false
      })
      .addCase(obtenerTablerosPorBusqueda.fulfilled, (state, {payload}) => {
        state.list = payload
        state.status = 'idle'
        state.filstrandoPorFavoritos = false
      })
      .addCase(obtenerTablerosFavoritos.pending, (state) => {
        state.status = 'loading'
        state.filstrandoPorFavoritos = true
      })
      .addCase(obtenerTablerosFavoritos.rejected, (state, {payload}) => {
        state.status = 'failed'
        state.errorMessage = payload?.errorMessage
        state.filstrandoPorFavoritos = true
      })
      .addCase(obtenerTablerosFavoritos.fulfilled, (state, {payload}) => {
        state.list = payload
        state.status = 'idle'
        state.filstrandoPorFavoritos = true
      })
      .addCase(marcarTableroFavorito.fulfilled, (state, {payload}) => {
        state.list =
          state.list &&
          state.list?.map((tablero) => {
            if (tablero.tableroId !== payload.tableroId) return tablero

            return {
              ...tablero,
              favorito: !tablero.favorito,
            }
          })
        state.status = 'idle'
      })
  },
})

export default TablerosSlice.reducer
