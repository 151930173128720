import {useEffect, useState} from 'react'
import {
  ListarTablerosFavoritosRequest,
  ListarTablerosPorAreaRequest,
  ListarTablerosPorBusquedaRequest,
  ToggleFavRequest,
} from '../services/TablerosService'
import {useAppDispatch, useAppSelector} from '../store/hooks'
import {
  marcarTableroFavorito,
  obtenerTableros,
  obtenerTablerosFavoritos,
  obtenerTablerosPorArea,
  obtenerTablerosPorBusqueda,
} from '../store/Tableros/actions'
import {
  selectTableros,
  selectTablerosFiltroFavorito,
  selectTablerosStatus,
} from '../store/Tableros/selectors'

export const useTableros = () => {
  const dispatch = useAppDispatch()
  const tableros = useAppSelector(selectTableros)
  const reqStatus = useAppSelector(selectTablerosStatus)
  const filtrandoPorFavoritos = useAppSelector(selectTablerosFiltroFavorito)

  const [favLoading, setFabLoading] = useState(false)

  useEffect(() => {
    if (tableros != null) return

    dispatch(obtenerTableros())
  }, [dispatch, tableros])

  function listarTableros() {
    dispatch(obtenerTableros())
  }

  function listarTablerosFavoritos(usuarioId?: ListarTablerosFavoritosRequest['usuarioId']) {
    if (usuarioId == null) return
    dispatch(obtenerTablerosFavoritos({usuarioId}))
  }

  function filtrarTablerosPorArea(areaId: ListarTablerosPorAreaRequest['areaId']) {
    dispatch(obtenerTablerosPorArea({areaId}))
  }

  function filtrarTablerosPorBusqueda(busqueda: ListarTablerosPorBusquedaRequest['busqueda']) {
    dispatch(obtenerTablerosPorBusqueda({busqueda}))
  }

  function toogleTableroFavorito(params: ToggleFavRequest) {
    setFabLoading(true)
    dispatch(marcarTableroFavorito(params)).finally(() => setFabLoading(false))
  }

  return {
    tableros,
    reqStatus,
    favLoading,
    filtrandoPorFavoritos,
    listarTableros,
    listarTablerosFavoritos,
    filtrarTablerosPorArea,
    filtrarTablerosPorBusqueda,
    toogleTableroFavorito,
  }
}
