import axios from 'axios'
import {getToken} from '../configs/adal.config'

const url = process.env.REACT_APP_API_URL

const instance = axios.create({
  baseURL: url,
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
})

export default instance
