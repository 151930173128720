import {AuthenticationContext, AdalConfig} from 'react-adal'

export const adalConfig: AdalConfig = {
  tenant: process.env.REACT_APP_TENANT, //Organizacion
  clientId: process.env.REACT_APP_CLIENT_ID as string, //id de la aplicacion
  redirectUri: process.env.REACT_APP_REDIRECT_URL, //url configurada para recibir la respuesde desde
  endpoints: {
    api: `https://${process.env.REACT_APP_TENANT}.onmicrosoft.com/${process.env.REACT_APP_CLIENT_ID}`, //https:// + TENANT + .onmicrosoft.com/ + CLIENT_ID
  },
  cacheLocation: process.env.REACT_APP_CACHE_LOCATION as 'localStorage' | 'sessionStorage',
}

export const authContext = new AuthenticationContext(adalConfig)

export const getToken = () => authContext.getCachedToken(adalConfig.clientId)
