import React from 'react'
import SVG from 'react-inlinesvg'
type Props = {
  className?: string
  url: string
  svgClassName?: string
}

const QKTSVG: React.FC<Props> = ({className = '', url, svgClassName = 'mh-50px'}) => {
  return (
    <span className={`svg-icon ${className}`}>
      <SVG src={url} className={svgClassName} fetchOptions={{mode:'no-cors'}}>
        <img src={url} alt="" />
      </SVG>
    </span>
  )
}

export {QKTSVG}
