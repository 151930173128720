import {useEffect, useState} from 'react'
import AreasService, {ListadoAreas} from '../services/AreasService'

type InfoRequest = {
  status: 'success' | 'failed'
  errorMessage?: string
}

export const useAreas = () => {
  //states
  const [areas, setAreas] = useState<ListadoAreas['areas']>()
  const [infoRequest, setinfoRequest] = useState<InfoRequest>()

  //effects
  useEffect(() => {
    fetchAreas()
  }, [])

  //methods
  async function fetchAreas() {
    try {
      const response = await AreasService.getAreas()
      if (response.status === 200) {
        setinfoRequest({status: 'success'})
        setAreas(response.data.areas)
      } else {
        throw new Error('Error al obtener las areas')
      }
    } catch (error) {
      console.log(error)
      setinfoRequest({
        status: 'failed',
        errorMessage: 'Error al obtener las areas',
      })
    }
  }

  return {
    areas,
    infoRequest,
    fetchAreas,
  }
}
